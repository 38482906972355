import MyDataHelps from '@careevolution/mydatahelps-js';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Dashboard from './views/Dashboard';
import Resources from "./views/Resources";
import Steps from "./views/Steps";
import Help from "./views/Help";

if (window.location.hostname === 'localhost' && !!process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN) {
    MyDataHelps.setParticipantAccessToken({'access_token': process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN, 'expires_in': 21600, 'token_type': 'Bearer'}, 'https://mydatahelps.org/');
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/dashboard/" element={<Dashboard/>}/>
                <Route path="/resources/" element={<Resources/>}/>
                <Route path="/help/" element={<Help/>}/>
                <Route path="/steps/" element={<Steps/>}/>
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
import React, {useEffect, useState} from "react";
import "./Metric.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import MyDataHelps, {DeviceDataNamespace, DeviceDataPoint, Guid} from "@careevolution/mydatahelps-js";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import * as Model from "@careevolution/mydatahelps-js/types";

const debounce = require('lodash.debounce');
const moment = require('moment');

export interface MetricType {
    namespace: DeviceDataNamespace;
    type: string;
    valueScale?: number;
}

export interface MetricProps {
    icon: IconProp;
    label: string;
    types: MetricType[];
    onClick?: () => void;
    fixLabel?: string;
    onFix?: () => void;
}

export default function (props: MetricProps) {
    const [dataPoint, setDataPoint] = useState<DeviceDataPoint>();

    const loadDataPointForType = (type: MetricType): Promise<DeviceDataPoint[]> => {
        let dataPoints: DeviceDataPoint[] = [];

        let getPage = function (pageID?: Guid): Promise<DeviceDataPoint[]> {
            let params: Model.DeviceDataPointQuery = {
                namespace: type.namespace,
                type: type.type,
                observedAfter: moment().add(-14, "days").toDate(),
                pageID: pageID
            };
            return MyDataHelps.queryDeviceData(params).then(function (response) {
                dataPoints = dataPoints.concat(response.deviceDataPoints.filter(dp => dp.value !== "0"));
                if (response.nextPageID) {
                    return getPage(response.nextPageID);
                } else {
                    return dataPoints;
                }
            });
        };

        return getPage();
    };

    const loadDataPoints = (types: MetricType[]): Promise<DeviceDataPoint[]> => {
        let dataPoints: DeviceDataPoint[] = [];

        let getDataPointsForType = function (type: MetricType): Promise<DeviceDataPoint[]> {
            return loadDataPointForType(type).then(function (typeDataPoints) {
                dataPoints = dataPoints.concat(typeDataPoints);
                if (types.length > 0) {
                    return getDataPointsForType(types.shift()!);
                } else {
                    return dataPoints;
                }
            });
        };

        return getDataPointsForType(types.shift()!);
    };

    function initialize() {
        if (props.types.length > 0) {
            loadDataPoints([...props.types]).then((dataPoints: DeviceDataPoint[]) => {
                if (dataPoints.length > 0) {
                    dataPoints.sort((a, b) => moment(b.observationDate).diff(moment(a.observationDate)));
                    setDataPoint(dataPoints[0]);
                }
            });
        }
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    function onClick() {
        if (props.onClick) {
            props.onClick();
        }
    }

    function onFix() {
        if (props.onFix) {
            props.onFix();
        }
    }

    if (!dataPoint) {
        return <div className="metric">
            <div>
                <div className="metric-label"><FontAwesomeIcon className="metric-icon" icon={props.icon}/>{props.label}</div>
                <div className="metric-value">No data</div>
            </div>
            {props.fixLabel && props.onFix &&
            <div className="metric-fix" onClick={() => onFix()}>{props.fixLabel}</div>
            }
        </div>;
    }

    let formattedDate: string;
    let observationDate = moment(dataPoint.observationDate);
    let observationDateDiff = moment().startOf('day').diff(moment(observationDate).startOf('day'), 'days');
    if (observationDateDiff > 1) {
        formattedDate = observationDate.format('M/D');
    } else if (observationDateDiff === 1) {
        formattedDate = 'Yesterday';
    } else {
        formattedDate = 'Today';
    }

    let value = dataPoint.value;
    if (!Number.isNaN(value)) {
        let valueAsNumber = Number(value);
        let metricType = props.types.find(t => t.namespace === dataPoint.namespace && t.type === dataPoint.type);
        if (metricType?.valueScale) {
            valueAsNumber = valueAsNumber * metricType.valueScale;
        }
        value = valueAsNumber.toLocaleString();
    }

    return <div className="metric" onClick={() => onClick()}>
        <div>
            <div className="metric-label"><FontAwesomeIcon className="metric-icon" icon={props.icon}/>{props.label}</div>
            <div className="metric-value">{value}{dataPoint.units}</div>
        </div>
        <div className="metric-date">{formattedDate}</div>
        <div className="metric-drill"><FontAwesomeIcon icon={faChevronRight}/></div>
    </div>;
}
import React, {useEffect} from 'react';
import './Help.css';
import MyDataHelps from '@careevolution/mydatahelps-js';
import {NavigationBar} from "@careevolution/mydatahelps-ui";

const debounce = require('lodash.debounce');

export default function () {

    function initialize() {
        // TODO.
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    function onCallMih() {
        MyDataHelps.openExternalUrl("tel:5084215588");
    }

    return (
        <div className="help">
            <NavigationBar title="" showCloseButton={true} children={"Medical Assistance"}/>
            <div className="text">If you aren't feeling well, our mobile integrated health paramedic can come to your house today to check on you and start treatment. You will also have a video visit with a doctor. Call 508-421-5588 and ask for a visit. We will make sure that you get the care you
                need today.
            </div>
            <button className="button" onClick={() => onCallMih()}>Call: 508-421-5588</button>
            <div className="text">
                <p>You can also always call your primary care doctor or specialist.</p>
                <p>If you think you are having a medical emergency, you should call 911 or go to the nearest emergency room.</p>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import './Steps.css';
import {NavigationBar} from '@careevolution/mydatahelps-ui';
import MyDataHelps, {DeviceDataPoint} from '@careevolution/mydatahelps-js';
import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import * as Model from "@careevolution/mydatahelps-js/types";

const debounce = require('lodash.debounce');
const moment = require('moment');

const now = moment();
const today = moment(now).startOf('day');

export default function () {
    const [dataPoints, setDataPoints] = useState<DeviceDataPoint[]>();

    function loadDeviceDataPoints() {
        let params: Model.DeviceDataPointQuery = {
            namespace: 'Fitbit',
            type: 'Steps',
            observedAfter: moment(today).add(-6, 'days').startOf('day')
        };

        MyDataHelps.queryDeviceData(params).then(function (results) {
            let dataPoints = results.deviceDataPoints;
            setDataPoints(dataPoints);
        });
    }

    function initialize() {
        loadDeviceDataPoints();
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    if (!dataPoints) {
        return null;
    }

    dataPoints.sort((a, b) => moment(a.observationDate).diff(moment(b.observationDate)));

    let steps = dataPoints.reduce(function (map: Record<string, number>, dp) {
        let key = moment(dp.observationDate).format("M/D");
        map[key] = map[key] || 0;
        map[key] += parseFloat(dp.value);
        return map;
    }, {});

    let data = Object.keys(steps).map(function (key) {
        return {
            key: key,
            value: steps[key]
        }
    });

    const renderCustomBarLabel = (props: any) => {
        if (props.value === 0) {
            return <text/>;
        }
        return <text x={props.x + props.width / 2} y={props.y} fill="#666" textAnchor="middle" dy={-6}>{props.value}</text>;
    };

    return (
        <div className="steps">
            <NavigationBar title="" showBackButton={true} children={<div className="logo"><img src="/images/logo.svg"/></div>}/>
            <div className="content">
                <ResponsiveContainer width='100%' aspect={4.0 / 3.0}>
                    <BarChart data={data}>
                        <Bar dataKey="value" barSize={20} label={renderCustomBarLabel} isAnimationActive={false}/>
                        <XAxis dataKey="key"/>
                        <YAxis/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
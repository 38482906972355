import React, {useEffect, useState} from "react";
import "./Mood.css";
import MyDataHelps, {SurveyAnswer} from "@careevolution/mydatahelps-js";
import * as Model from "@careevolution/mydatahelps-js/types";

const debounce = require('lodash.debounce');
const moment = require('moment');

export interface MoodProps {
    onClick: Function
}

export default function (props: MoodProps) {
    const [loadingMood, setLoadingMood] = useState<boolean>(true);
    const [mood, setMood] = useState<SurveyAnswer>();

    function initialize() {
        let params: Model.SurveyAnswersQuery = {
            surveyName: 'Single Item Wellness',
            stepIdentifier: 'MOOD',
            after: moment().startOf('day').add(-7, 'days')
        };

        MyDataHelps.querySurveyAnswers(params).then(function (results) {
            let surveyAnswers = results.surveyAnswers;
            if (surveyAnswers.length > 0) {
                surveyAnswers.sort((a, b) => moment(b.date).diff(moment(a.date)));
                setMood(surveyAnswers[0]);
            }
            setLoadingMood(false);
        });
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    function onClick() {
        if (props.onClick && canUpdateMood()) {
            props.onClick();
        }
    }

    function canUpdateMood() {
        return !mood || !moment().startOf('day').isBefore(moment(mood.date));
    }

    if (loadingMood) {
        return null;
    }

    return <div className="mood cta-group" onClick={() => onClick()}>
        <div>
            <div className="mood-text">How are you feeling today?</div>
            {mood &&
            <div className="mood-date">
                {moment(mood.date).format('M/D h:ma')}
                {canUpdateMood() &&
                <div className="mood-update">update</div>
                }
            </div>
            }
        </div>
        {mood
            ? <div className="mood-score selected"><img src={'/images/face-' + mood.answers[0] + '.png'} alt={'face' + mood.answers[0]}/></div>
            : <div className="mood-score"><img src={'/images/face-3.png'} alt="face3"/></div>
        }

    </div>;
}
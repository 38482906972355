import React, {useEffect} from 'react';
import './Resources.css';
import MyDataHelps from '@careevolution/mydatahelps-js';

const debounce = require('lodash.debounce');

export default function () {

    function initialize() {
        // TODO.
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    return (
        <div className="resources">Placeholder for resources.</div>
    )
}